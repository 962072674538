/* Customize your main colors in :root variables */
:root {
  --main-background-color: #79B4FF;
  --card-background-color: #EFEFEF;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:black;
  --title-text-color:white;
  --list-title-text-color:black;
  --secondary-attribute-color:#79B4FF;
}

body{
/*  background-color:var(--main-background-color);*/
  background: url("img/background.png");
  font-family: 'Patrick Hand', cursive;
  font-size: 1.2em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



